import React, { useState } from "react"
import SubmitButton from "../../../utils/button"
import Paystack, { setGlobalConfig } from "paystack-simple"

const paymentInstance = Paystack()

setGlobalConfig({ key: process.env.GATSBY_PAYSTACK_PUBLIC_KEY })

const DonationForm = () => {
  const [formValues, setFormValues] = useState({
    emailAddress: '',
    amount: '',
    firstName: '',
    lastName: '',
  })

  const onInputChange = ({ target }) => {
    setFormValues(prevState => ({
      ...prevState,
      [target.id]: target.value
    }))
  }

  const onSuccess = () => {
    setFormValues({
      emailAddress: '',
      amount: '',
      firstName: '',
      lastName: '',
    })
  }

  const handleDonation = async e => {
    e.preventDefault()

    await paymentInstance.addOptions({
      label: `${formValues.firstName} ${formValues.lastName}`,
      metadata: {
        firstName: formValues.firstName,
        lastName: formValues.lastName,
      },
      amount: formValues.amount * 100,
      email: formValues.emailAddress,

      callback: () => {
        onSuccess()
      },
    })

    paymentInstance.pay()
  }
  return (
    <form name="donate" onSubmit={handleDonation}>
      <input type="hidden" name="form-name" value="contact" />
      <section className="form-wrapper md:mt-[90px]">
        <div>
          {/* First Name */}
          <div className="form-group">
            <input
              type="text"
              required="required"
              name="firstName"
              id="firstName"
              placeholder="First Name"
              onChange={onInputChange}
              value={formValues.firstName}
            />
          </div>

          {/* Last Name*/}
          <div className="form-group">
            <input
              type="text"
              required="required"
              name="lastName"
              id="lastName"
              placeholder="Last Name"
              onChange={onInputChange}
              value={formValues.lastName}
            />
          </div>

          <div className="form-group">
            <input
              type="email"
              required="required"
              name="emailAddress"
              id="emailAddress"
              placeholder="Email Address"
              onChange={onInputChange}
              value={formValues.emailAddress}
            />
          </div>

          {/* Amount */}
          <div className="form-group">
            <input
              type="number"
              required="required"
              name="amount"
              id="amount"
              min="1000"
              placeholder="Amount"
              onChange={onInputChange}
              value={formValues.amount}
              step="500"
            />
          </div>

          <div className="form-group">
            <SubmitButton type='submit' />
          </div>
        </div>
      </section>
    </form>
  )
}

export default DonationForm
