import React from "react"

const DonateAccountDetails = () => {
  return (
    <section>
      <p className="text-p14 max-w-[238px]">
        Do you prefer a direct bank transfer? We’ve got you covered.
      </p>
      <p>
        Dear Designer
        <br />
       7810012867
        <br />
        Wema Bank
      </p>
    </section>
  )
}

export default DonateAccountDetails
