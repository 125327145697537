import React from "react"
import ctl from "@netlify/classnames-template-literals"
import { Link } from "gatsby"

const Button = ({ text, light, url, className: _className  = '', ...props }) => {
  const color = ctl(`
  ${light ? "white" : "#6D6D6D"}
  `)

  const className = `uppercase border-none font-medium flex justify-center items-center tracking-widest text-p16 ${color} ${_className}`

  if(!url){
    return (
      <button className={className} {...props}>
        {text}
        <svg
          width="17"
          height="14"
          viewBox="0 0 17 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="inline-block ml-4 "
        >
          <path
            d="M10.1245 1.00001L15.9673 6.84277L10.1245 12.6855"
            stroke={color}
          />
          <line y1="6.49292" x2="16" y2="6.49292" stroke={color} />
        </svg>
      </button>
    )
  }

  return (
    <Link
      to={url}
      className={className}
      {...props}
    >
      {text}
      <svg
        width="17"
        height="14"
        viewBox="0 0 17 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="inline-block ml-4 "
      >
        <path
          d="M10.1245 1.00001L15.9673 6.84277L10.1245 12.6855"
          stroke={color}
        />
        <line y1="6.49292" x2="16" y2="6.49292" stroke={color} />
      </svg>
    </Link>
  )
}

export default Button
