import React from "react"
import PageHeading from "../../utils/page-heading"
import { StaticImage } from "gatsby-plugin-image"

const DonateIntro = () => {
  return (
    <>
      <StaticImage
        src="../../../images/DonatePage.png"
        placeholder="blurred"
        className="w-full"
      />

      <PageHeading text="Support/Partner With Us" />
      <p>
        As a non-profit organization, our activities over the years have been
        largely funded by the generous donations from individuals and partner
        organizations that believe in our vision for a better design community.
        Since our inception till date, we have facilitated several capacity
        building sessions for designers in Nigeria.
      </p>
      <p>
        We are now looking to expand our coverage to the larger African design
        community and we’d need your support. Your donations help keep our work
        alive, it’s why it means a lot to us.
      </p>
      <p>
        Continue with the form below to make a direct donation or Get in touch
        with us to learn how you can support any of our upcoming events as a
        partner.
      </p>
    </>
  )
}

export default DonateIntro
