import React from "react"
import PagesLayout from "../components/layout/pages"
import {
  DonateAccountDetails,
  DonateIntro,
  DonationForm,
} from "../components/pages/donate/"

const Donate = () => {
  return (
    <PagesLayout title="Donate">
      <DonateIntro />
      <DonationForm />
      <DonateAccountDetails />
    </PagesLayout>
  )
}

Donate.propTypes = {}

export default Donate
